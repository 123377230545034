import React from "react";

const items = [
  {
    imageLink: "no-risk.svg",
    imageName: "no-risk",
    text: "You will not get banned nor experience any risks to your account.",
  },
  {
    imageLink: "/data-secured.svg",
    imageName: "data-secured",
    text:
      "All your personal data is kept safe and will not be disclosed to any party, in accordance with the PDPA.",
  },
  {
    imageLink: "/bank-safe.svg",
    imageName: "bank-safe",
    text:
      "With a third party payment process, you won’t need to reveal banking information.",
  },
];

const Trusted: React.FunctionComponent = () => {
  return (
    <div className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-7xl">
        <div className="flex flex-col justify-center items-center">
          <h2 className="nunito-sans max-w-2xl font-bold text-yellow-300 text-center text-4xl leading-tight mb-12">
            Kohai.gg is trusted, credible and legally compliant. It ensures a
            safe user experience by adhering to the necessary laws and
            regulations.
          </h2>

          <div className="grid md:grid-cols-3 my-6 gap-x-24">
            {items.map(({ imageLink, imageName, text }, index) => (
              <div className="mt-4 mb-12 pb-10">
                <div key={index} className="flex flex-col">
                  <img
                    src={imageLink}
                    alt={imageName}
                    className="flex-shrink-0 h-36 mb-10"
                  />
                  <p className="text-white text-xl text-center">{text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
