import React, { useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { useMeQuery } from "src/generated/graphql";
import { Transition } from "@headlessui/react";
import { setCookie } from "nookies";
import { useRouter } from "next/router";

const Navbar: React.FunctionComponent = () => {
  const { data } = useMeQuery();
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
  const menu = useRef(null);
  const router = useRouter();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!menu.current.contains(event.target)) {
        if (!showDrawer) return;
        setShowDrawer(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [showDrawer, menu]);

  const signOut = async () => {
    setCookie(null, process.env.COOKIE, null, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });

    router.push("/");
  };

  return (
    <nav className="absolute flex justify-center p-4 w-full z-50">
      <div className="container">
        <div className="flex justify-between items-center w-full max-w-screen-xl">
          <div className="flex items-center">
            <img
              src="/kohai-logo.png"
              alt="kohai-logo"
              style={{width: '120px'}}
            />
            <div className="hidden md:flex">
              <a href="/#how-it-works" className="text-white nav-link">
                How It Works
              </a>
              <a href="/#perks" className="text-white nav-link">
                Perks
              </a>
              <a href="/#about-us" className="text-white nav-link">
                About Us
              </a>
            </div>
          </div>
          <div className="flex items-center">
            {data?.pilotMe?.id ? (
              <div className="hidden md:flex space-x-4">
                <Link href="/pilot/dashboard">
                  <a className="nav-link text-white">Partner Dashboard</a>
                </Link>
                <a className="nav-link cursor-pointer text-white" onClick={signOut}>
                  Sign Out
                </a>
              </div>
            ) : (
              <>
                <Link href="/sign-in">
                  <a className="hidden md:flex text-white nav-link">Login</a>
                </Link>
                <div className="hidden md:flex space-x-4">
                  <Link href="/sign-up">
                    <a className="text-white nav-link">Sign Up</a>
                  </Link>
                </div>
              </>
            )}

            <div className="md:hidden" onClick={() => setShowDrawer(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#FFFFFF"
                className="text-white w-8"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>

        <div ref={menu}>
          <Transition
            show={showDrawer}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5 space-y-6">
                    <div className="flex items-center justify-end">
                      <div className="-mr-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          onClick={() => setShowDrawer(false)}
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <nav className="grid gap-y-8">
                        {data?.pilotMe?.id ? (
                          <>
                            <Link href="/pilot/dashboard">
                              <a className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-blue-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                  />
                                </svg>
                                <div className="text-base leading-6 font-medium text-gray-900">
                                  Partner Dashboard
                                </div>
                              </a>
                            </Link>

                            <a
                              className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                              onClick={signOut}
                            >
                              <svg
                                className="flex-shrink-0 h-6 w-6 text-blue-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                              <div className="text-base leading-6 font-medium text-gray-900">
                                Sign Out
                              </div>
                            </a>
                          </>
                        ) : (
                          <>
                            <Link href="/sign-in">
                              <a className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-blue-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                  />
                                </svg>
                                <div className="text-base leading-6 font-medium text-gray-900">
                                  Login
                                </div>
                              </a>
                            </Link>
                            <Link href="/sign-up">
                              <a className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-blue-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                                  />
                                </svg>
                                <div className="text-base leading-6 font-medium text-gray-900">
                                  Sign Up
                                </div>
                              </a>
                            </Link>
                          </>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
