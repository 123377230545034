import React from "react";

const AboutUs: React.FunctionComponent = () => {
  return (
    <div id="about-us" className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-5xl">
        <div className="md:grid md:grid-cols-4 md:gap-6">
          <h2 className="nunito-sans font-bold text-yellow-300 text-5xl mb-4">
            About Us
          </h2>
          <div className="col-span-3">
            <p className="nunito-sans text-2xl text-white">
              Your love and expertise for gaming can now be turned into a
              lucrative opportunity. Kohai connects lovers and fans of esports
              alike to come together to innovate the mobile gaming experience
              for all. Monetize your skill by just playing with other users
              while helping them up-level their skills at the same time. Usher
              in esports’ biggest revolution yet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
