import React from "react";
import { PageGameComp } from "src/generated/page";
import Navbar from "src/components/Navbar/Navbar";
import Hero from "../components/Home/Hero";
import Content from "src/components/Home/Content";
import Footer from "src/components/Footer/Footer";

const Home: PageGameComp = () => {
  return (
    <div className="bg-backdrop text-white">
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
};

export default Home;
